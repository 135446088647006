import React, {useState} from 'react'
import { BlogContainer,Card,CardImage,CardTitle,CardText,CardButton, CardContainer} from './BlogElements'
import { Link } from 'react-router-dom'

import { cardData } from './data'

const Blog = () => {

    return (
        <BlogContainer id="blog">
            <CardContainer>
            {cardData.map((card, index) => (
                <Link to={`/blog/${index}`} style={{textDecoration:'none', color:'black'}}>
                    <Card key={index}>
                        <CardImage src={card.image} alt="Card Image" />
                        <CardTitle>{card.title}</CardTitle>
                        {/* <CardText>{card.text.substring(0,200)+"..."}</CardText> */}
                        <CardText dangerouslySetInnerHTML={{ __html: card.text.substring(0,200)+"..."}} />
                        <CardButton to={`/blog/${index}`}>Read more</CardButton>
                    </Card>
                </Link>
            ))}
            </CardContainer>
        </BlogContainer>
    )
}

export default Blog