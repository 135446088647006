import React, {useState} from 'react'
import { HeroContainer,HeroBg,VideoBg,HeroContent,HeroH1,HeroP,HeroBtnWrapper,ArrowForward,ArrowRight } from './HeroElements'
import {Button} from '../ButtonElement'

const HeroSection = () => {
    const [hover,setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            {/* <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='/video/mp4'/>
            </HeroBg> */}
            <HeroContent>
                <HeroH1>Connecting your workers with equipment that fits.</HeroH1>
                <HeroP>Accurate and Easy Human Body Measurements for the Workwear Industry, powered by AI</HeroP>
                <HeroBtnWrapper>
                    <Button 
                        href="https://light.esenca.app/levis"
                        target='_blank'
                        onMouseEnter={onHover} 
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        >
                        Get Started {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection