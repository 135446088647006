import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'
import { SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from '../Footer/FooterElements'
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaLinkedin} from 'react-icons/fa'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-4.svg'
import Icon3 from '../../images/svg-5.svg'

import DragosPhoto from '../../images/team/dragos.png';
import EdiPhoto from '../../images/team/edi.png';
import CosminPhoto from '../../images/team/cosmin.png';
import MihaiPhoto from '../../images/team/mihai.png';
import TheoPhoto from '../../images/team/teo.png';

const Services = () => {
    
    const url_linkedin_dragos = 'https://www.linkedin.com/in/dragos-tanasa/';
    const url_linkedin_edi = 'https://www.linkedin.com/in/eduard-cojocea-a003b28b/';
    const url_linkedin_cosmin = 'https://www.linkedin.com/in/cosmin-gabriel-ciocirlan-b60191195/';
    const url_linkedin_mihai = 'https://www.linkedin.com/in/mihai-petre/';
    const url_linkedin_theo = 'https://www.linkedin.com/in/cristina-theodora-ghita-763b751b5/';

    const text_color = "#283b71";

    return(
        <ServicesContainer id="team">
            <ServicesH1>Our Team</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={DragosPhoto}/>
                    <ServicesH2>Dragos</ServicesH2>
                    <ServicesP>
                        CEO
                    </ServicesP>
                    <ServicesP>
                        <SocialIconLink href={url_linkedin_dragos} target='_blank' aria-label='LinkedIn'>
                            <FaLinkedin color={text_color}/>
                        </SocialIconLink>
                    </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={EdiPhoto}/>
                    <ServicesH2>Eduard</ServicesH2>
                    <ServicesP>
                        CTO
                    </ServicesP>
                    <ServicesP>
                        <SocialIconLink href={url_linkedin_edi} target='_blank' aria-label='LinkedIn'>
                            <FaLinkedin color={text_color}/>
                        </SocialIconLink>
                    </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={CosminPhoto}/>
                    <ServicesH2>Cosmin</ServicesH2>
                    <ServicesP>
                        CPO
                    </ServicesP>
                    <ServicesP>
                        <SocialIconLink href={url_linkedin_cosmin} target='_blank' aria-label='LinkedIn'>
                            <FaLinkedin color={text_color}/>
                        </SocialIconLink>
                    </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={MihaiPhoto}/>
                    <ServicesH2>Mihai</ServicesH2>
                    <ServicesP>
                        COO
                    </ServicesP>
                    <ServicesP>
                        <SocialIconLink href={url_linkedin_mihai} target='_blank' aria-label='LinkedIn'>
                            <FaLinkedin color={text_color}/>
                        </SocialIconLink>
                    </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={TheoPhoto}/>
                    <ServicesH2>Theo</ServicesH2>
                    <ServicesP>
                        Data Scientist
                    </ServicesP>
                    <ServicesP>
                        <SocialIconLink href={url_linkedin_theo} target='_blank' aria-label='LinkedIn'>
                            <FaLinkedin color={text_color}/>
                        </SocialIconLink>
                    </ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services