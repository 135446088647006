import React from 'react'
import { SidebarContainer,Icon,CloseIcon,SidebarWrapper,SidebarMenu,SidebarLink,SideBtnWrap,SidebarRoute, BlogLink} from './SidebarElements';

const Sidebar = ({isOpen,toggle}) => {
    return(
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='discover' onClick={toggle}>Discover</SidebarLink>
                    <SidebarLink to='try' onClick={toggle}>Try Now</SidebarLink>
                    <SidebarLink to='team' onClick={toggle}>Our Team</SidebarLink>
                    <BlogLink to='/blog' onClick={toggle}>Blog</BlogLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute href="https://light.esenca.app/levis" target='_blank'>Try Now</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;