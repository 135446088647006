import React, {useState,useEffect} from 'react';
import {FaBars} from 'react-icons/fa'
import {Nav,NavbarContainer,NavLogo,MobileIcon,NavMenu,NavItem,NavLinks,NavBtn,NavBtnLink,BlogLink,NavBtnMobile} from './NavbarElements';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll'

import { useLocation } from 'react-router-dom';

const Navbar =  ({toggle}) => {
    const [scrollNav,setScrollNav] = useState(false)

    let location = useLocation();
    
    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll',changeNav)
    },[])

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}><i>EDW</i></NavLogo>
                    {location.pathname ==='/' && 
                    <>
                    <MobileIcon onClick={toggle}>
                        <FaBars color={"#283b71"}/>
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='discover'
                            smooth={true}
                            duration={500}
                            spy={true}
                            // offset={-80}
                            exact='true'>Discover</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='try'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'>Try Now</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='team'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'>Our Team</NavLinks>
                        </NavItem>
                        <NavItem>
                            <BlogLink to='/blog'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'>Blog</BlogLink>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink href="https://light.esenca.app/levis" target='_blank'>Try Now</NavBtnLink>
                    </NavBtn>
                    </>}
                    {location.pathname ==='/blog' && 
                    <>
                    <NavBtnMobile>
                        <NavBtnLink href="https://light.esenca.app/levis" target='_blank'>Try Now</NavBtnLink>
                    </NavBtnMobile>
                    </>}
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    )
};

export default Navbar;