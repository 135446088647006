import React, {useState} from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Blog from '../components/Blog'

const BlogPage = ()  => {
    const [isOpen,setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return(
        <>
            {/* <Sidebar isOpen={isOpen} toggle={toggle}/> */}
            <Navbar toggle={toggle}/>
            <Blog/>
            
        </>
    )
}

export default BlogPage;