import styled from 'styled-components'

export const BlogContainer = styled.div`
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    width: 80%;
    margin: 0 auto;

    @media screen and (max-width: 820px){
        width: 100%;
    }
`

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  width:100%;
  padding:10px;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 50px 50px;
  margin-top:20px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 400px;
  padding: 30px;
  object-fit: cover;

@media screen and (max-width: 820px){
  object-fit: contain;
  padding: 0px;
  }
`;

export const CardTitle = styled.h3`
  margin-top: 10px;
`;

export const CardText = styled.p`
  padding: 20px;
  margin-top: 10px;
  text-align: justify;
`;

export const CardButton = styled.a`
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;

    border-radius: 50px;
    background: #88b3c6;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border: 1px solid #fff;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
        border: 1px solid #283b71;
    }
`;