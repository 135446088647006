export const homeObjOne = {
    id: 'discover',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Solution',
    headline: 'Say goodbye to manual measurements',
    description: ' and hello to accurate, reliable data with our cutting-edge software solutions for the workwear industry.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/ww_3.svg').default,
    alt: 'Alt1',
    dark: true,
    primary: true,
    darkText: false
}


export const homeObjTwo = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Fully GDPR-compliant',
    headline: 'Take two photos',
    description: 'Using our web app, guided by our Virtual Assistant. Everything under 30 seconds.',
    buttonLabel: 'Start Now',
    imgStart: true,
    img: require('../../images/dragos_cartoon.svg').default,
    alt: 'Alt3',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Harness the power of AI',
    headline: 'Scan the whole body instantly',
    description: "Our AI extracts more than 100 body measurements, with 5 mms precision. Then recommends your worker's best fit.",
    buttonLabel: 'Test here',
    imgStart: false,
    img: require('../../images/measurements.svg').default,
    alt: 'Alt3',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjFour = {
    id: 'try',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Special Access',
    headline: 'Test our solution now',
    description: 'Still have doubts? See for yourself.',
    buttonLabel: 'Try now',
    imgStart: true,
    img: require('../../images/ww_2.svg').default,
    alt: 'Alt2',
    dark: false,
    primary: false,
    darkText: true
}