import React, {useState,useEffect} from 'react'

import Sidebar from './../Sidebar'
import Navbar from './../Navbar'

import { BlogContainer,Card,CardImage,CardTitle,CardText,CardButton, CardContainer} from './BlogEntryElements'

import { cardData } from '../Blog/data'

import { useParams } from 'react-router-dom';

const BlogEntry = () => {
    const { index } = useParams();

    const [entry, setEntry] = useState([]);

    const [isOpen,setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(()=>{
        if(cardData){
          let entry = cardData[index]
          setEntry(entry)
        }
      },[])

    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <BlogContainer id="blog">
            <CardContainer>
                <Card key={entry.title}>
                    <CardImage src={entry.image} alt="Card Image" />
                    <CardTitle>{entry.title}</CardTitle>
                    <CardText dangerouslySetInnerHTML={{ __html: entry.text}} />
                </Card>
            </CardContainer>
        </BlogContainer>
        </>
    )
}

export default BlogEntry