import React, {useState} from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../components/InfoSection/data';
import Services from '../components/Services';
import Footer from '../components/Footer';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initGA } from '../ga-utils';

const Home = ()  => {
    const [isOpen,setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const handleAcceptCookie = () => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        }
    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    return(
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection/>
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <InfoSection {...homeObjThree}/>
            <InfoSection {...homeObjFour}/>
            <Services/>
            <Footer/>
            <CookieConsent
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </>
    )
}

export default Home;