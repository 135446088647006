import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/home';
import BlogPage from './pages/blog';
import BlogEntry from './components/BlogEntry';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} exact/>
        <Route path="/blog" element={<BlogPage/>} exact/>
        <Route exact path="/blog/:index" element={<BlogEntry/>}/>
      </Routes>
    </Router>
  );
}

export default App;
